.contactImg {
    height: 510px;
    width: 100%;
    display: flex;
    background: url("https://placeimg.com/1000/450/people");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.contactImg > span {
    color: white;
    font-size: 48px;
    margin: auto;
    text-shadow: 0 0 3px black, 0 0 5px black;
}

.tarjetaContacto {
    margin-top: 80px; 
    margin-bottom: 80px;
    padding: 25px;
    background-color: rgba(244, 244, 244, 1) !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.newParagraph {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.newParagraphGrid {
    margin-top: 15px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.cardTitle {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #242424 !important;
}

.number {
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.mail {
    color: #07394e !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.direction {
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.5;
}

.directionSmall {
    color: #6b6b6b !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5;
}