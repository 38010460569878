.contactImg {
    height: 510px;
    width: 100%;
    display: flex;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.contactImg > span {
    color: white;
    font-size: 48px;
    margin: auto;
    text-shadow: 0 0 3px black, 0 0 5px black;
}

.serviceContainer {
    padding-top: 50px;
    padding-bottom: 50px;
}

.titleServiceDescription {
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-align: center;
    margin-top: 15px !important;
}