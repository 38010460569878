a {
  text-decoration: none;
  color: #07394e;
}

img {
  width: 100%;
}

.links {
  margin-top: 50px;
  margin-bottom: 50px;
}

.beforeFooter {
  width: 100%;
  background-color: #07394e;
  height: 130px;
}

.footerContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer {
  width: 100%;
  padding: '142px 0px 142px 0px';
  background-color: black;
}

.footerText {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerText > span { 
  font-weight: 300;
  font-size: 14px;
}