.root {
    margin-bottom: 50px;
}

.each-fade {
    display: flex;
    width: 100%;
}

.each-fade > div {
    width: 100%;
    height: 450px;
    display: flex;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.each-fade > div > h2 {
    padding: 15px;
    font-weight: 700;
    color: white;
    margin: auto;
    position: relative;
    text-shadow: 0 0 3px black, 0 0 5px black;
}

.indicator {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border: 1px #666 solid
}
  
.indicator.active {
    color: #fff;
    background: #666;
}

.slideText {
    text-align: center;
}

@media only screen and (max-width: 470px) {
    .slideText {
        font-size: 2.75rem !important;
    }

    ol {
        padding: 20px;
    }
}

@media only screen and (max-width: 370px) {
    .slideText {
        font-size: 1.75rem !important;
    }
}

@media only screen and (max-width: 260px) {
    .slideText {
        font-size: 0.75rem !important;
    }
}